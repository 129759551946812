import { createAction } from "@reduxjs/toolkit";
import { CallApiPayload, ConfigEnvironment, GenericError, VoicemeToken } from "./types";

export const callApiStart = createAction<CallApiPayload>("call-api/start");
export const apiToken = createAction<VoicemeToken>("call-api/token");

export const configEndpoint = createAction<{environments: ConfigEnvironment[]}>("call-api/config");
export const configFailed = createAction<GenericError>("call-api/failed");
export const configFlowSetup = createAction<{state: 'idle' | 'start' | 'progress'}>("call-api/flow/config");

export const INIT = createAction("voiceme/init");

import { MouseEventHandler } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Children } from '../../type.d';
import VoicemeButton from './VoicemeButton';

interface Props extends Children {
    title: string
    description: string
    button: string
    buttonIcon?: string
}

function VoicemeBanner({ title,  description, button, buttonIcon }: Props) {
    const handler: MouseEventHandler<HTMLElement> = (event) => {
        //selection?.(!isSelected)
    }

    return (
        <Row className="d-flex align-items-center justify-content-center text-center mt-5 mb-5 me-2 ms-2 p-2">
            <Col onClick={handler} className="text-light bg-primary" style={{borderRadius: '20px'}}>
                <Row>
                    <Col xs={12} className=" mt-4 mb-4 fs-3" >
                        {title}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mt-4 mb-4" >
                        {description}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mb-5 text-sm-center" >
                        <VoicemeButton onClick={() => { }} >{buttonIcon ?  <Image className='me-3' src={buttonIcon}/> : <></>}{button}</VoicemeButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default VoicemeBanner;

import { MouseEventHandler, useEffect, useState } from 'react';
import { Row, Col, Stack, Image, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router';
import { connectorLogin, PropsLogin } from '../../app/login/store';
import voiceme from '../../assets/images/voiceme.svg';
import top from '../../assets/images/landing/top-background.svg'
import top2 from '../../assets/images/landing/top-background-2.svg'
import bottom from '../../assets/images/landing/bottom-background.svg'
import voicemeSquare from '../../assets/images/landing/voiceme-square.svg'
import voice from '../../assets/images/landing/voice.svg'
import shopping from '../../assets/images/landing/shopping.svg'
import webAuth from '../../assets/images/web-auth.svg'
import mobileAuth from '../../assets/images/mobile-auth.svg'
import block1 from '../../assets/images/landing/block1.svg'
import block2 from '../../assets/images/landing/block2.svg'
import block3 from '../../assets/images/landing/block3.svg'
import { Link } from 'react-router-dom';
import VoicemeCard from '../atoms/VoicemeCard';
import VoicemeButton from '../atoms/VoicemeButton';
import QRCode from "react-qr-code";
import voicemeIcon from '../../assets/images/voiceme-icon.svg';

interface Props  {
    onClick: MouseEventHandler<HTMLButtonElement>
    value: string
}


function QrCode({ value, onClick }: Props) {
    return (
        <Row className="d-flex-fluid align-items-center justify-content-center text-center">
            <Col xs={4}></Col>
            <Col xs={4}>
                <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-4">
                    <Col><h4 className='text-center'>QrCode</h4></Col>
                </Row>
                <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-1">
                    <Col><h5 className='text-center'>Scan the qr code to start biometric profile creation.</h5></Col>
                </Row>
                <Row style={{}} className="d-flex-fluid align-items-center justify-content-center text-center">
                    <Col >
                        <div style={{ height: '400px', position: 'relative' }}>
                            <Image style={{ opacity: '0.3', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }} src={voicemeIcon} />
                            <QRCode
                                size={512}
                                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) scale(0.4,0.4)', zIndex: '1' }}
                                value={value}
                                viewBox={`0 0 512 512`}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-5">
                    <Col><VoicemeButton onClick={onClick}>Continue</VoicemeButton></Col>
                </Row>
            </Col>
            <Col xs={4}></Col>
        </Row>
    );
}

export default QrCode;

import { Action, CombinedState } from "@reduxjs/toolkit";
import { StateObservable } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { map, filter, withLatestFrom, switchMap, catchError } from "rxjs/operators";
import { NotificationStore } from "../notification/store";
import { callApiStart, configEndpoint, configFlowSetup } from "./actions";
import { APIStore } from "./store";

export const fetchData = ({ url, method, body, auth }: { url: string, method: string, body?: any, auth?: string }) => {
  const headers : HeadersInit = auth ? {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${auth}`,
  } : {
    "Content-Type": "application/json",
  }
  return fetch(`${url}`, {
    method: method,
    headers: headers,
    body: body,
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(json => {
          throw json;
        });
      }
      if(response.status === 204) {
        return {}
      }
      return response.json();
    });
}

export const callApiProcessing = (
  action$: Observable<Action>,
  state$: StateObservable<CombinedState<{ notification: NotificationStore; api: APIStore; }>>
): Observable<Action> =>
  action$.pipe(
    filter(callApiStart.match),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      from(fetchData({...action.payload, auth: action.payload.auth ? state.api.token?.access_token : undefined})).pipe(
        map(response => action.payload.success(response)),
        catchError(error => {
          return of(action.payload.reject(error))
        })
      ))
  );

export const epicConfigEndpoint = (action$: Observable<Action>, state$: Observable<APIStore>) =>
  action$.pipe(
      filter(configEndpoint.match),
      map(action => configFlowSetup({state: 'idle'})));

import { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import Success from './Success';
import config from '../../assets/images/config.svg'
import { connectorAPI } from '../../app/api/store';

interface Prop {
    onClick: ({ config }: { config: string }) => void
}

function Config({ onClick }: Prop) {
    const [validatedConfig, setValidatedConfig] = useState('false');
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        const configField = form.elements.config;
        onClick({ config: configField.value })
        event.preventDefault();
    }

    const handleValidation = (event: any) => {
        const form = event.currentTarget;
        const configField = form.elements.config;
        
        // Email validation
        const configRegex = /^\S+$/;
        if (!configRegex.test(configField.value)) {
            configField.setCustomValidity("Please enter a valid config string.");
            setValidatedConfig('false');
        } else {
            setValidatedConfig('true');
            configField.setCustomValidity("");
        }
        setValidated(true)
    };
    return (
        <Form noValidate validated={validated} onChange={handleValidation} onSubmit={handleSubmit}>
            <Success image={config} title="App" message='Please enter your Configuration Environment' subMessage='We will configure the subsystem to your environment' button='Invio' onClick={()=>{}} type="submit">
                <Form.Group validated={validatedConfig} className="w-100" as={Col} md="4" controlId="config">
                    <Form.Control
                        required
                        type="text"
                        placeholder="Config"
                        defaultValue=""
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
            </Success>
        </Form>
    );
}

export default connectorAPI(Config);

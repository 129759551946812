import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { createEpicMiddleware, combineEpics, Epic } from "redux-observable";
import { epicNotificationGetTrigger, epicNotificationProcessed, epicNotificationTrigger } from './notification/epic';
import notificationStore from './notification/reducer';
import apiStore from './api/reducer';
import routerStore from './router/reducer';
import loginStore from './login/reducer';
import enrollStore from './enroll/reducer';
import { callApiProcessing, epicConfigEndpoint } from './api/epic';
import { epicConfirmProcessed, epicConfirmTrigger, epicEnrollGetTrigger, epicEnrollTrigger, epicSignupTrigger } from './enroll/epic';
import { epicLoginFlowMobileComplete, epicLoginFlowMobileFail, epicLoginFlowMobileOtp, epicLoginFlowWebNoMoreRetry, epicLoginFlowWebNoMoreRetryOnFail, epicLoginFlowWebProcessed, epicLoginFlowWebRecorderLoader, epicLoginFlowWebSentence, epicLoginGetTrigger, epicLoginPostFailed, epicLoginPostProcessed, epicLoginProcessed, epicLoginTrigger } from './login/epic';

const reducers = combineReducers({
  notification: notificationStore,
  api: apiStore,
  router: routerStore,
  login: loginStore,
  enroll: enrollStore,
});

const rootEpic = combineEpics<Action<any>, Action<any>, void, any>(
  callApiProcessing as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicNotificationTrigger as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicNotificationProcessed as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicNotificationGetTrigger as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicSignupTrigger as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicConfirmTrigger as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicConfirmProcessed as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginProcessed as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicEnrollTrigger as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicEnrollGetTrigger as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginGetTrigger as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginTrigger as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginPostProcessed as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginPostFailed as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginFlowMobileOtp as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginFlowMobileComplete as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginFlowWebSentence as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginFlowWebRecorderLoader as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginFlowWebProcessed as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginFlowWebNoMoreRetry as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginFlowMobileFail as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicLoginFlowWebNoMoreRetryOnFail as unknown as Epic<Action<any>, Action<any>, void, any>,
  epicConfigEndpoint as unknown as Epic<Action<any>, Action<any>, void, any>,
)

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: reducers,
  middleware: [epicMiddleware],
  }
);

epicMiddleware.run(rootEpic);


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import errorImage from '../../assets/images/error.svg';
import Success from '../organism/Success';
import { useAppDispatch } from '../../app/hooks';
import Loader from '../organism/Loader';
import { PropsAPI, connectorAPI } from '../../app/api/store';
import Config from '../organism/Config';
import { callApiStart, configEndpoint, configFailed, configFlowSetup } from '../../app/api/actions';

interface Props extends PropsAPI {
}

function ConfigFlow({ api, flowAPI }: Props) {
    const dispatch = useAppDispatch()

    const performConfig = ({config}:{config: string}) => {
        dispatch(
            callApiStart({
                url: `https://api.voiceme-production.demo.infra.voiceme.id/voiceme/configuration/?search=${config}`,
                method: "GET",
                success: configEndpoint,
                reject: configFailed,
                auth: false,
            }))
        dispatch(configFlowSetup({ state: 'progress' }))
    }
    const handleReconfigure = () => {
        dispatch(configFlowSetup({ state: 'start' }))
    }
    const cancel = () => {
        dispatch(configFlowSetup({ state: 'idle' }))
    }
    if(flowAPI.status === 'start') {
        return <Config onClick={performConfig}/>
    } else if(flowAPI.status === 'progress') {
        return <Loader message='Loading' subMessage='we are looking for your config' onClick={cancel} />
    }else {
        return (<Success image={errorImage} button='Re-configure' onClick={handleReconfigure} title='Configuration' message={"Configuration " + (api?.endpoint ? 'ok' : 'not good') } subMessage=""></Success>)
    }
}
export default connectorAPI(ConfigFlow);

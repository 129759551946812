import { createSlice } from '@reduxjs/toolkit';
import { routerTo } from './actions';
import { RouterStore } from './store';

const initialState: RouterStore = {
  path: ''
};

const routerStore = createSlice({
  name: 'router',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(routerTo, (state, action) => {
        state.path = action.payload.path;
      })
  }
});

export default routerStore.reducer;

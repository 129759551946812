import { Action, ActionCreatorWithPayload, CombinedState } from "@reduxjs/toolkit";
import { StateObservable } from "redux-observable";
import { from, Observable, of, zip } from "rxjs";
import { map, filter, ignoreElements, withLatestFrom, switchMap, catchError, mergeMap, tap } from "rxjs/operators";
import { apiToken, callApiStart } from "../api/actions";
import { GenericError } from "../api/types";
import { notificationFailed, notificationGetFailed, notificationGetProcessed, notificationProcessed, notificationTrigger } from "../notification/actions";
import { RootState } from "../store";
import { loginFailed, loginFlowSetup, loginGetFailed, loginGetProcessed, loginGetTrigger, loginProcessed, loginTrigger, setupLogin } from "./actions";

export const epicLoginProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(action => notificationProcessed.match(action) || notificationGetProcessed.match(action)),
        withLatestFrom(state$),
        filter(([, state]) => state.notification.notificationStatus === 'SUCCESS' && state.notification.emailSetup !== undefined),
        map(([, state]) => setupLogin({ email: state.notification.emailSetup! }))
    );

export const epicLoginPostProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginProcessed.match),
        map(action => apiToken(action.payload))
    );

export const epicLoginPostFailed = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginFailed.match),
        withLatestFrom(state$),
        map(([, state]) => loginGetTrigger({ session_id: state.login.login?.session_id }))
    );

export const epicLoginTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginTrigger.match),
        withLatestFrom(state$),
        filter(([, state]) => state.login.loginStatus === 'loading' && !!state.api.api),
        map(([action, state]) => {
            return callApiStart({
                url: `${state.api.api!.endpoint}${state.api.api!.login}`,
                method: "POST",
                body: JSON.stringify({ ...action.payload }),
                success: loginProcessed,
                reject: loginFailed,
                auth: true,
            })
        })
    );

export const epicLoginGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginGetTrigger.match),
        withLatestFrom(state$),
        filter(([, state]) => state.login.loginStatus === 'loading' && !!state.api.api),
        map(([action, state]) => {
            return callApiStart({
                url: `${state.api.api!.endpoint}${state.api.api!.login}${action.payload.session_id ? '?session_id=' + action.payload.session_id : ''}`,
                method: "GET",
                success: loginGetProcessed,
                reject: loginGetFailed,
                auth: true,
            })
        })
    );

export const epicLoginFlowMobileOtp = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginFlowSetup.match),
        withLatestFrom(state$),
        filter(([action, state]) => state.login.flow.stage === 'waitForOtp'),
        mergeMap(([action, state]) => of(
            setupLogin({ email: action.payload.email }),
            notificationTrigger({ email: action.payload.email, custom_body: "login request", service_id: "VOICEME" })
        ))
    );


export const epicLoginFlowMobileComplete = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(action => notificationProcessed.match(action) || notificationGetProcessed.match(action)),
        withLatestFrom(state$),
        filter(([, state]) => state.login.flow.stage === 'waitForOtp' && state.notification.emailSetup !== undefined),
        filter(([, state]) => state.notification.notificationStatus === 'SUCCESS' || state.notification.notificationStatus === 'FAILED'),
        mergeMap(([action, state]) => of(
            loginFlowSetup({ stage: state.notification.notificationStatus === 'SUCCESS' ? 'successMobile' : 'failedMobile' })
        ))
    );

export const epicLoginFlowMobileFail = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(notificationFailed.match),
        withLatestFrom(state$),
        map(([action, state]) => {
            const error : GenericError = {
                code: action.payload.code || 'GenericError',
                detail: action.payload.detail || 'Unknown error'
            }
            return loginFlowSetup({ stage: 'failedWebReset', error: error })
        }));

export const epicLoginFlowWebSentence = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginFlowSetup.match),
        withLatestFrom(state$),
        filter(([action, state]) => state.login.flow.stage === 'recording'),
        mergeMap(([action, state]) => of(
            setupLogin({ email: action.payload.email }),
            loginGetTrigger(state.login?.login?.session_id ? { session_id: state.login?.login?.session_id } : {})
        ))
    );

export const epicLoginFlowWebRecorderLoader = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginFlowSetup.match),
        withLatestFrom(state$),
        filter(([action, state]) => state.login.flow.stage === 'recordingLoader' && action.payload?.requestLogin !== undefined),
        map(([action, state]) => {
            if(action.payload.requestLogin?.email === '' || action.payload.requestLogin?.email === undefined){
                const error = {
                    code: 'Wrong state',
                    detail: 'retry operation'
                }
                return loginFlowSetup({ stage: 'start', error: error })
            }
            return loginTrigger(action.payload.requestLogin!)
        })
    );

export const epicLoginFlowWebProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(action => loginProcessed.match(action) || loginFailed.match(action)),
        withLatestFrom(state$),
        filter(([, state]) => state.login.flow.stage === 'recordingLoader'),
        mergeMap(([action, state]) => {
            if (loginProcessed.match(action)) return of(loginFlowSetup({ stage: 'successWeb' }))
            return of(
                loginFlowSetup({ stage: 'failedWeb' })
                )
        })
    );

export const epicLoginFlowWebNoMoreRetryOnFail = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginGetFailed.match),
        withLatestFrom(state$),
        filter(([action, state]) => state.login.flow.stage === 'failedWeb' && action.payload.code === 'FailedSessionError'),
        map(([action, state]) => {
            const error = {
                code: action.payload.code || 'GenericError',
                detail: action.payload.detail || 'Unknown error'
            }
            return loginFlowSetup({ stage: 'failedWebReset', error: error })
        })
    );

export const epicLoginFlowWebNoMoreRetry = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(loginGetProcessed.match),
        withLatestFrom(state$),
        filter(([action, state]) => action.payload.max_attempts <= 0),
        mergeMap(([action, state]) => {
            const error = {
                code: 'FailedSessionError',
                detail: 'No more retry'
            }
            return of(
                loginGetFailed(error),
                loginFlowSetup({ stage: 'failedWebReset', error: error }),
            )
        })
    );

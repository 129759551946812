import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router';
import { connectorLogin, PropsLogin } from '../../app/login/store';
import vme from '../../assets/images/vme.svg';
import { useAppDispatch } from '../../app/hooks';
import { setupLogin } from '../../app/login/actions';

interface Props extends PropsLogin {
  bg?: string
  mode?: string
}

function VoicemeHeader({email, phone} : Props) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [login, setLogin] = useState('Login')
  useEffect(() => {
    if(email !== '') {
      setLogin(email.replace(/[-.]/, ' ').replace(/@.*/, ''))
    } else if(phone !== '') {
      setLogin(phone.replace(/^\+../, ''))
    } else setLogin('Login')
  },[email, phone])

  return (
    <Navbar collapseOnSelect expand="lg" bg={"primary"} variant={"dark"}>
      <Container>
        <Navbar.Brand onClick={() => navigate('/')}><img alt="voiceme" src={vme}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link onClick={() => navigate('/config')}>Configure</Nav.Link>
            <Nav.Link onClick={() => navigate('/services')}>Services</Nav.Link>
            <Nav.Link onClick={() => navigate('/')}>About Us</Nav.Link>
            <Nav.Link >Profile</Nav.Link>
            <Nav.Link onClick={() => navigate('/register')}>Register</Nav.Link>
            <Nav.Link onClick={() => {navigate('/login')}}>{login}</Nav.Link>
            <Nav.Link onClick={() => {dispatch(setupLogin({}))}}>Reset</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default connectorLogin(VoicemeHeader);

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store";
import { InitApiPayload, VoicemeToken } from "./types";

export interface APIFlow {
    status: 'start' | 'progress' | 'idle'
}

export interface APIStore {
    request: any,
    response: any,
    api?: InitApiPayload,
    token?: VoicemeToken,
    flowAPI: APIFlow,
}

const mapState = (state: RootState) => ({
    ...state.api,
})

const mapDispatch = {
    toggleOn: () => ({ type: 'TOGGLE_IS_ON' }),
}

export const connectorAPI = connect(mapState, mapDispatch)

export type PropsAPI = ConnectedProps<typeof connectorAPI>

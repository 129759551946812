
import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { Children } from '../../type.d';

interface Props extends Children {
    onClick: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    type?: "button" | "submit" | "reset" | undefined
    invert?: boolean
}

function VoicemeButton({ children, disabled=false, onClick, type = 'button', invert = false }: Props) {
    return (
        <Button disabled={disabled} onClick={onClick} className="ps-5 pe-5 pt-1 pb-1" type={type} style={{ color: invert ? 'white' : 'var(--voiceme-primary-accent)'  , border: 'none', background: invert ? "var(--voiceme-primary-accent)" : "white", textAlign: 'center', borderRadius: '20px' }}>{children}</Button>
    )
}

export default VoicemeButton;

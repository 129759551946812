import React, { ReactNode, useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./components/error-page/error-page";
import Services from "./components/organism/Services";

import "./index.scss";
import { VoicemePage } from "./components/pages/voiceme-page";
import { Provider } from "react-redux";
import { store } from "./app/store";
import ReactDOM from "react-dom";
import Success from "./components/organism/Success";
import VoicemeLanding from "./components/pages/voiceme-landing";
import RegisterFlow from "./components/pages/RegisterFlow";
import LoginFlow from "./components/pages/LoginFlow";
import PaymentFlow from "./components/pages/PaymentFlow";
import RecordView from "./components/organism/Recorder";
import { useAppDispatch } from "./app/hooks";
import { INIT } from "./app/api/actions";
import VoicemeRecorder from "./components/organism/recorder/VoicemeRecorder";
import errorImage from './assets/images/error.svg';
import ConfigFlow from "./components/pages/ConfigFlow";



const router = createBrowserRouter([
  {
    path: "/",
    element: <VoicemeLanding />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/services",
    element: <VoicemePage><Services></Services></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/notify",
    element: <VoicemePage><PaymentFlow /></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/failed",
    element: <VoicemePage><Success image={errorImage} title='Error' message="Purchase failed" subMessage="Unable to recognize"></Success></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/success",
    element: <VoicemePage><Success></Success></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <VoicemePage><RegisterFlow></RegisterFlow></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <VoicemePage><LoginFlow ></LoginFlow></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/video",
    element: <VoicemePage><RecordView ></RecordView></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/recorder",
    element: <VoicemePage><VoicemeRecorder cancel={()=>{}} action={(p)=>console.log(p)}></VoicemeRecorder></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/config",
    element: <VoicemePage><ConfigFlow ></ConfigFlow></VoicemePage>,
    errorElement: <ErrorPage />
  }
]);

export function InitVoiceme({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(INIT())
  }, [dispatch])
  return (<> {children} </>)
}

ReactDOM.render(
  <Provider store={store} >
    <React.StrictMode>
      <InitVoiceme><RouterProvider router={router} /></InitVoiceme>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

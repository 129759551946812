import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router';
import { connectorLogin, PropsLogin } from '../../app/login/store';
import errorImage from '../../assets/images/error.svg';
import MobileWebSelector from '../organism/MobileWebSelector';
import Success from '../organism/Success';
import Login from '../organism/Login';
import { useAppDispatch } from '../../app/hooks';
import Loader from '../organism/Loader';
import { connectorNotification, PropsNotification } from '../../app/notification/store';
import { loginFlowSetup } from '../../app/login/actions';
import VoicemeRecorder from '../organism/recorder/VoicemeRecorder';
import { LoginPostPayload } from '../../app/login/types';
import { EnrollPayload } from '../../app/enroll/types';
import { PropsAPI, connectorAPI } from '../../app/api/store';

interface Props extends PropsNotification, PropsLogin, PropsAPI {
}

function LoginFlow(props: Props) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const mobileWebSelection: (val: string) => void = (val) => {
        if (props.email === '' || props.email === undefined) {
            if (val === 'mobile') {
                dispatch(loginFlowSetup({ stage: 'mobile' }))
            } else if (val === 'web') {
                dispatch(loginFlowSetup({ stage: 'web' }))
            }
        } else {
            if (val === 'mobile') {
                dispatch(loginFlowSetup({ stage: 'waitForOtp', email: props.email }))
            } else if (val === 'web') {
                dispatch(loginFlowSetup({ stage: 'recording', email: props.email }))
            }
        }
    }
    const loginMobileHandler = ({ email: mail }: { email: string }) => {
        dispatch(loginFlowSetup({ stage: 'waitForOtp', email: mail }))
    }
    const loginWebHandler = ({ email: mail }: { email: string }) => {
        dispatch(loginFlowSetup({ stage: 'recording', email: mail }))
    }
    const cleanup: MouseEventHandler<HTMLButtonElement> = (event) => {
        dispatch(loginFlowSetup({ stage: 'start' }))
    }
    const toServices: MouseEventHandler<HTMLButtonElement> = (event) => {
        navigate('/services')
        cleanup(event)
    }
    const handleRecording = (payload: LoginPostPayload | EnrollPayload) => {
        if (props.login?.session_id) dispatch(loginFlowSetup({
            stage: 'recordingLoader',
            requestLogin: {
                ...payload,
                session_id: props.login?.session_id,
                email: props.email,
            }
        }))
        else dispatch(loginFlowSetup({ stage: 'start' }))
    }
    const handleRetryWeb: MouseEventHandler<HTMLButtonElement> = (event) => {
        if (props.login?.max_attempts && props.login?.max_attempts > 0) {
            dispatch(loginFlowSetup({ stage: 'recording', email: props.email }))
        } else cleanup(event)
    }

    const cancelRequest = () => {
        dispatch(loginFlowSetup({ stage: 'start' }))
    }
    if(!props.api?.endpoint) {
        navigate('/config')
        return (<Success image={errorImage} onClick={() => navigate('/config')} title='Error' message="Environment is not configured, please configure it" subMessage="Use the header menu Configure"></Success>)
    }
    switch (props.flow.stage) {
        default:
        case 'start':
        case 'select': return (<MobileWebSelector choiceSelection={mobileWebSelection}></MobileWebSelector>)
        case 'mobile': return (<Login onClick={loginMobileHandler}></Login>)
        case 'sentence':
        case 'waitForOtp':
            return (<Loader onClick={cleanup}></Loader>)
        case 'failedMobileReset':
            return (<Success image={errorImage} onClick={cleanup} title='Error' message="DeviceToken" subMessage="Unable to recognize"></Success>)
        case 'failedMobile':
            return (<Success image={errorImage} onClick={cleanup} title='Error' message="Authentication failed" subMessage="Unable to recognize"></Success>)
        case 'successMobile':
        case 'successWeb':
            return (<Success button="Enter in VoiceMe" title='Welcome Back!' message='Successfully logged in discover the latest voiceme news!' subMessage='' onClick={toServices} ></Success>)
        case 'web': return (<Login onClick={loginWebHandler}></Login>)
        case 'recording':
            return (<VoicemeRecorder cancel={cancelRequest} sentence={props.login?.sentence} duration={props.login?.media_length} attempt={props.login?.max_attempts} action={handleRecording}></VoicemeRecorder>)
        case 'recordingLoader':
            return (<Loader onClick={cleanup}></Loader>)
        case 'failedWeb':
            return (<Success image={errorImage} onClick={handleRetryWeb} title='Error' message="Authentication failed" subMessage="Unable to recognize"></Success>)
        case 'failedWebReset':{
            let errorFix = props.flow.error
            if (errorFix?.code === undefined || errorFix.detail === undefined) {
                errorFix = {
                    code: 'GenericError',
                    detail: 'Unknown error'
                }
            }
            return (<Success image={errorImage} onClick={cleanup} title='Error' message={errorFix?.detail} subMessage={errorFix?.code}></Success>)
        }
    }
}
export default connectorAPI(connectorLogin(connectorNotification(LoginFlow)));

import { MouseEventHandler, useEffect, useState } from 'react';
import { Row, Col, Stack, Image, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router';
import voiceme from '../../assets/images/voiceme.svg';
import top from '../../assets/images/landing/top-background.svg'
import top2 from '../../assets/images/landing/top-background-2.svg'
import bottom from '../../assets/images/landing/bottom-background.svg'
import voicemeSquare from '../../assets/images/landing/voiceme-square.svg'
import voice from '../../assets/images/landing/voice.svg'
import shopping from '../../assets/images/landing/shopping.svg'
import webAuth from '../../assets/images/web-auth.svg'
import mobileAuth from '../../assets/images/mobile-auth.svg'
import block1 from '../../assets/images/landing/block1.svg'
import block2 from '../../assets/images/landing/block2.svg'
import block3 from '../../assets/images/landing/block3.svg'
import { Link } from 'react-router-dom';
import VoicemeCard from '../atoms/VoicemeCard';
import VoicemeButton from '../atoms/VoicemeButton';
import QRCode from "react-qr-code";
import voicemeIcon from '../../assets/images/voiceme-icon.svg';
import Register from '../organism/Register';
import MobileWebSelector from '../organism/MobileWebSelector';
import QrCode from '../organism/QrCode';
import Success from '../organism/Success';
import errorImage from '../../assets/images/error.svg';
import Login from '../organism/Login';
import { useAppDispatch } from '../../app/hooks';
import { notificationTrigger } from '../../app/notification/actions';
import Loader from '../organism/Loader';
import { connectorNotification, PropsNotification } from '../../app/notification/store';
import { connectorLogin, PropsLogin } from '../../app/login/store';
import { connectorAPI, PropsAPI } from '../../app/api/store';

interface Props extends PropsNotification, PropsLogin, PropsAPI {
}


function PaymentFlow({ error, notificationStatus, email, api }: Props) {
    const [state, setState] = useState('start')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const successHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
        navigate('/services')
    }
    useEffect(() => {
        if (api?.endpoint) {
            dispatch(notificationTrigger({ email, service_id: "TRANSACTION" }))
        }
        return () => { }
    }, [api])
    useEffect(() => {
        if (notificationStatus === 'SUCCESS' && error === undefined) {
            setState('success')
        } else if (notificationStatus === 'FAILED' && error === undefined) {
            setState('fail')
        } else if (error !== undefined) {
            setState('error')
        }
    }, [notificationStatus, error])
    switch (state) {
        case 'success':
            return (<Success onClick={successHandler} ></Success>)
        case 'error':
            return (<Success image={errorImage} onClick={() => navigate('/services')} title='Error' message={error?.code} subMessage={error?.detail}></Success>)
        case 'fail':
            return (<Success image={errorImage} onClick={() => navigate('/services')} title='Error' message="Authentication failed" subMessage="Unable to recognize"></Success>)
        case 'loader':
        case 'start':
        default:
            return (<Loader  onClick={()=>{}} title="Otp notification" message='Open the voiceme app, we have sent you a confirmation notification' subMessage='' ></Loader>)
    }
}

export default connectorAPI(connectorLogin(connectorNotification(PaymentFlow)));

import { createSlice } from '@reduxjs/toolkit';
import { apiToken, callApiStart, configEndpoint, configFlowSetup, INIT } from './actions';
import { APIStore } from './store';

const initialState: APIStore = {
  request: {},
  response: {},
  flowAPI:{
    status: 'idle'
  }
};

const apiStore = createSlice({
  name: 'api',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(callApiStart, (state, action) => {
        state.request = action.payload
        state.response = {}
      })
      .addCase(apiToken, (state, action) => {
        state.token = action.payload
      })
      .addCase(configEndpoint, (state, action) => {
        if (state.api) {
          state.api.endpoint = `https://${action.payload.environments[0].url}/`
          localStorage.setItem("url", state.api.endpoint)
        }
      })
      .addCase(configFlowSetup, (state, action) => {
        if (state.api) {
          state.flowAPI.status = action.payload.state
        }
      })
      .addCase(INIT, (state, action) => {
        if (!state.api) {
          state.api = {
            confirm: "voiceme/profile/confirm/",
            endpoint: localStorage.getItem("url") || '',
            enroll: "voiceme/enroll/",
            login: "voiceme/authorize/",
            notifications: "voiceme/notifications/",
            signup: "voiceme/signup/",
          }
        }
      })
  },
});

export default apiStore.reducer;

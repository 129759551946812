import { MouseEventHandler } from 'react';
import { Col, Image, Row, Spinner } from 'react-bootstrap';
import voicemeIcon from '../../assets/images/voiceme-icon.svg';
import { Children } from '../../type.d';
import VoicemeButton from '../atoms/VoicemeButton';

interface Props extends Children {
    image?: string
    title?: string
    message?: string
    subMessage?: string
    onClick: MouseEventHandler<HTMLButtonElement>
}
function Loader({
        title = "Operation in progress!",
        message = "please wait",
        subMessage = "" ,
        onClick,
        children,
    }: Props) {
    return (
        <>
            <Row className="d-flex-fluid align-items-center justify-content-center text-center">
                <Col xs={4}></Col>
                <Col xs={4}>
                    <Row className="d-flex-fluid align-items-center justify-content-center text-center">
                        <Col >
                            <div style={{ height: '400px', position: 'relative' }}>
                                <Image style={{ opacity: '1', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }} src={voicemeIcon} />
                                <div
                                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) scale(2,2)', zIndex: '1' }}
                                ><Spinner></Spinner></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-4">
                        <Col><h4 className='text-center'><strong>{title}</strong><br />{message}</h4></Col>
                    </Row>
                    {children && (<Row className="d-flex-fluid align-items-center justify-content-center text-center mt-4">
                        <Col>{children}</Col>
                    </Row>)}
                    <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-1">
                        <Col><h5 className='text-center'>{subMessage}</h5></Col>
                    </Row>
                </Col>
                <Col xs={4}></Col>
            </Row>
            <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-5">
                <Col><VoicemeButton type="button" onClick={onClick}>Cancel</VoicemeButton></Col>
            </Row>
        </>
    );
}

export default Loader;

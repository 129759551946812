import { useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { connectorLogin, PropsLogin } from '../../app/login/store';
import top from '../../assets/images/landing-top.svg'
import bottom from '../../assets/images/landing/bottom-background.svg'
import voicemeSquare from '../../assets/images/landing/voiceme-square.svg'
import VoicemeHeader from './voiceme-header';
import voice from '../../assets/images/landing/voice.svg'
import phone1 from '../../assets/images/phone-1.svg'
import auth from '../../assets/images/auth.svg'
import confirm from '../../assets/images/confirm.svg'
import enrollment from '../../assets/images/enrollment.svg'
import voiceOtp from '../../assets/images/voice-otp.svg'
import voiceKey from '../../assets/images/voice-key.svg'
import voicePay from '../../assets/images/voice-pay.svg'
import shopping from '../../assets/images/landing/shopping.svg'
import block1 from '../../assets/images/landing/block1.svg'
import block3 from '../../assets/images/landing/block3.svg'
import VoicemeButton from '../atoms/VoicemeButton';

interface Props extends PropsLogin {

}

function VoicemeLanding({ email, phone }: Props) {
    const navigate = useNavigate()

    return (
        <Row className="w-100">
            <Col xs={12}>
                <div style={{ position: 'absolute', zIndex: -1, top: 0, width: '100%', height: '1500px', backgroundImage: `url("${top}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                <Row >
                    <Col xs={12} className="me-0 d-flex-fluid" >
                        <Row>
                            <Col xs={12} className="d-flex-fluid">
                                <VoicemeHeader mode='dark'></VoicemeHeader>
                            </Col>
                        </Row>
                        <Row className="mt-5 pt-5 ">
                            <Col xs={8} className="d-flex-fluid mb-5 pt-5 align-items-center justify-content-center text-center text-primary" >
                                <Row ><Col xs={12} className="mb-5 pt-5"> .</Col></Row>
                                <Row ><Col xs={12} className="fs-1"> VOICEME</Col></Row>
                                <Row><Col xs={12} className="fs-3">Your Voice, The One and Only</Col></Row>
                                <Row><Col xs={12} className="mt-3"><VoicemeButton invert={true} onClick={() => navigate('/register')} >ENTER</VoicemeButton></Col>
                                </Row>
                            </Col>
                            <Col xs={4} className="d-flex mb-5 pt-5 align-items-center justify-content-center text-center" >
                                <Image onClick={() => navigate('/register')} src={phone1} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="" style={{ backgroundColor: 'var(--voiceme-bg)' }}>
                    <Col xs={3}></Col>
                    <Col xs={6} className="d-flex-fluid align-items-center justify-content-center text-center text-black" style={{ height: '200px', background: "white", textAlign: 'center', borderRadius: '20px' }}>
                        <Row className="d-flex text-center mt-5 fs-6">
                            <Col >
                                <Row><Col xs={12}><Image src={enrollment} width={70} height={70} /></Col></Row>
                                <Row><Col xs={12}>Enrollment</Col></Row>
                            </Col>
                            <Col >
                                <Row><Col xs={12}><Image src={confirm} width={70} height={70} /></Col></Row>
                                <Row><Col xs={12}>Authorizing</Col></Row>
                            </Col>
                            <Col >
                                <Row><Col xs={12}><Image src={auth} width={70} height={70} /></Col></Row>
                                <Row><Col xs={12}>Identity and Actions <br />Confirmation</Col></Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={3}></Col>
                </Row>
                <div className="mt-5 fs-5 " style={{ backgroundColor: 'var(--voiceme-bg)' }}>
                    <Row className="mt-5" >
                        <Col xs={3}></Col>
                        <Col xs={2} className="mt-5">
                            <Row><Col xs={12} className="text-primary fs-1">VoiceKey</Col></Row>
                            <Row><Col xs={12}>Access to digital properties. Use your voice and five other authentication factors to identify yourself</Col></Row>
                        </Col>
                        <Col xs={5}><Image src={voiceKey} /></Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="mt-5" >
                        <Col xs={3}></Col>
                        <Col xs={2} className="mt-5">
                            <Row><Col xs={12} className="text-primary fs-1">VoicePay</Col></Row>
                            <Row><Col xs={12}>Thanks to our Patented solution, use your Voice to Pay and transfer Money</Col></Row>
                        </Col>
                        <Col xs={5}><Image src={voicePay} /></Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="mt-5" >
                        <Col xs={3}></Col>
                        <Col xs={2} className="mt-5">
                            <Row><Col xs={12} className="text-primary fs-1">VoiceOTP</Col></Row>
                            <Row><Col xs={12}>One notification - three authentication factors. eIDAS and PSD2 compliant, with VOICEOTP confirm your Identity and actions</Col></Row>
                        </Col>
                        <Col xs={5}><Image src={voiceOtp} /></Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row  >
                        <Col xs={12} style={{ height: '300px', backgroundImage: `url("${bottom}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}

export default connectorLogin(VoicemeLanding);

import { connect, ConnectedProps } from "react-redux";
import { GenericError } from "../api/types";
import { RootState } from "../store";
import { LoginGetPayload, LoginGetResponse, LoginPostPayload, FlowLogin } from "./types";

export interface LoginStore {
    email: string
    phone: string
    login?: LoginGetResponse
    loginStatus?: string
    loginError?: GenericError
    loginOk?: boolean
    loginRequest?: LoginGetPayload | LoginPostPayload
    flow: FlowLogin
}

const mapState = (state: RootState) => ({
    ...state.login,
})

const mapDispatch = {
}

export const connectorLogin = connect(mapState, mapDispatch)

export type PropsLogin = ConnectedProps<typeof connectorLogin>

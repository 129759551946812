import { MouseEventHandler } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../app/hooks';
import success from '../../assets/images/success.svg';
import { Children } from '../../type.d';
import VoicemeButton from '../atoms/VoicemeButton';

interface Props extends Children {
    onClick?: MouseEventHandler<HTMLButtonElement>
    image?: string
    title?: string
    message?: string
    subMessage?: string
    button?: string
    type?: "button" | "submit" | "reset" | undefined
}
function Success({
        onClick,
        image = success,
        title = "Congratulations!",
        message = "you have completed your purchase.",
        subMessage = "We have sent the details of your purchase to your email." ,
        button = "Continue",
        type = "button",
        children,
    }: Props) {
    const navigate = useNavigate()
    const onClickCalc = onClick || (() => {navigate('/services')})
    return (
        <Row className="d-flex-fluid align-items-center justify-content-center text-center text-primary">
            <Col xs={4}></Col>
            <Col xs={4}>
                <Row className="d-flex-fluid align-items-center justify-content-center text-center">
                    <Col><Image src={image} alt="success" /></Col>
                </Row>
                <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-4">
                    <Col><h4 className='text-center'><strong>{title}</strong><br />{message}</h4></Col>
                </Row>
                {children && (<Row className="d-flex-fluid align-items-center justify-content-center text-center mt-4">
                    <Col>{children}</Col>
                </Row>)}
                <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-1">
                    <Col><h5 className='text-center'>{subMessage}</h5></Col>
                </Row>
                <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-5">
                    <Col><VoicemeButton type={type} onClick={onClickCalc}>{button}</VoicemeButton></Col>
                </Row>
            </Col>
            <Col xs={4}></Col>
        </Row>
    );
}

export default Success;

import { createAction } from "@reduxjs/toolkit";
import { GenericError } from "../api/types";
import { NotificationDescriptor, NotificationPayload, NotificationResponse } from "./types";

export const notificationTrigger = createAction<NotificationPayload>("notification/post/trigger");
export const notificationProcessed = createAction<NotificationResponse>("notification/post/processed");
export const notificationFailed = createAction<GenericError>("notification/post/failed");

export const notificationGetTrigger = createAction<NotificationDescriptor>("notification/get/trigger");
export const notificationGetProcessed = createAction<NotificationResponse>("notification/get/processed");
export const notificationGetFailed = createAction<GenericError>("notification/get/failed");

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import { IRecording, RecorderInstance } from './Recorder';
import VoicemeButton from '../../atoms/VoicemeButton';
import voicemeBouble from '../../../assets/images/voiceme-bouble.svg'
import { Button, Image } from 'react-bootstrap';
import { LoginPostPayload } from '../../../app/login/types';
import { EnrollPayload } from '../../../app/enroll/types';

interface Props {
    sentence?: string
    action: (payload: EnrollPayload | LoginPostPayload)=>void
    cancel: ()=>void
    attempt?: number
    duration?: number
}


const VoicemeRecorder = ({sentence, action, attempt, duration = 5, cancel = ()=>{} } : Props) => {
    const [videoStream, setVideoStream] = useState<MediaStream>();
    const [isRecordingStart, setIsRecordingStart] = useState<boolean>(false)
    
    const authorizeVoiceme = async ({ video, videoType, audio, audioType }: IRecording) => {
        action({
            audio: {
                type: audioType,
                dataURL: audio
            },
            video: {
                type: videoType,
                dataURL: video
            }
        })
    }
    const startRecording = (recordingDuration: number) => {
        if (videoStream) {
            if (isRecordingStart) {
                // console.log("Recording in progress");
            } else {
                RecorderInstance.startVideoRecording();
                // console.log("Recording started");
                setIsRecordingStart(true);
                setTimeout(() => {
                    requestRecording();
                }, recordingDuration * 1000);
            }
        } else {
            alert("Allow your browser to use your camera and microphone");
        }
    }

    const requestRecording = async () => {
        const recording = await RecorderInstance.requestRecording();
        // console.log("Recording stopped");
        setIsRecordingStart(false);
        authorizeVoiceme(recording);
    }

    const getPermissions = async () => {
            // console.log("CREATE STREAM")
            const videoStream = await RecorderInstance.getVideoStream();
        setVideoStream(videoStream);
    }

    const onClickCancel = () => {
        cancel()
    }

    useEffect(() => {
        if ("MediaRecorder" in window) {
            getPermissions().catch(console.error);
        } else {
            console.log(`The MediaRecorder API is not supported in your browser`);
        }
    }, [])

    return (
        <Container>
            <Row >
                <Col className='d-flex-fluid w-100 text-center text-primary'>
                    <Row><Col xs={12} className="fs-1">Read the sentence (remain {attempt} attempt)</Col></Row>
                    <Row><Col xs={12} className="fs-4">Look at the webcam and read the sentence</Col></Row>
                    <Row><Col xs={12} className="fs-2">{sentence}</Col></Row>
                    <Row><Col xs={12}  >{videoStream && <VideoPlayer srcObject={videoStream}/>}</Col></Row>
                    <Row><Col xs={12} ><Button style={{border: 'none', backgroundColor: (isRecordingStart ? 'rgba(255,0,0,255)' : 'rgba(0,0,0,0)')}} onClick={() => startRecording(duration)}><Image src={voicemeBouble}></Image></Button></Col></Row>
                    <Row><Col xs={12} ><VoicemeButton onClick={onClickCancel}>Cancel</VoicemeButton></Col></Row>
                </Col>
            </Row>
        </Container>
    )
}

export default VoicemeRecorder;

import { createSlice } from '@reduxjs/toolkit';
import { INIT } from '../api/actions';
import { loginFailed, loginFlowSetup, loginGetFailed, loginGetProcessed, loginGetTrigger, loginProcessed, loginTrigger, setupLogin } from './actions';
import { LoginStore } from './store';

const initialState: LoginStore = {
  email: '',
  phone: '',
  flow: {}
};

const loginStore = createSlice({
  name: 'login',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupLogin, (state, action) => {
        state.email = action.payload.email || ''
        state.phone = action.payload.phone || ''
        if(action.payload.email) localStorage.setItem("email", action.payload.email)
        else localStorage.removeItem("email")
        if(action.payload.phone) localStorage.setItem("phone", action.payload.phone)
        else localStorage.removeItem("phone")
      })
    .addCase(INIT, (state) => {
      state.email = localStorage.getItem("email") || ''
      state.phone = localStorage.getItem("phone") || ''
    })
    .addCase(loginTrigger, (state, action) => {
      state.loginStatus = 'loading'
      state.loginRequest = {
        ...action.payload
      }
      state.loginError = undefined
      state.loginOk = undefined
    })
    .addCase(loginProcessed, (state) => {
      state.loginStatus = 'idle'
      state.loginError = undefined
      state.login = undefined
      state.loginOk = true
    })
    .addCase(loginFailed, (state, action) => {
      state.loginStatus = 'failed'
      state.loginError = action.payload
      state.loginOk = false
    })
    .addCase(loginGetTrigger, (state, action) => {
      state.loginStatus = 'loading'
      state.loginRequest = {
        ...action.payload
      }
      state.login = undefined
      state.loginError = undefined
      state.loginOk = false
    })
    .addCase(loginGetProcessed, (state, action) => {
      state.loginStatus = 'idle'
      state.login = action.payload
      state.loginError = undefined
    })
    .addCase(loginGetFailed, (state, action) => {
      state.loginStatus = 'failed'
      state.loginError = action.payload
      state.login = undefined
    })
    .addCase(loginFlowSetup, (state, action) => {
      state.flow.stage = action.payload.stage
      if(action.payload.email !== undefined) state.flow.email = action.payload.email
      if(action.payload.error !== undefined) state.flow.error = action.payload.error
    })
  },
});

export default loginStore.reducer;

import { connect, ConnectedProps } from "react-redux";
import { GenericError } from "../api/types";
import { RootState } from "../store";
import { ConfirmPayload, EnrollGetPayload, EnrollGetResponse, EnrollPayload, SignupPayload } from "./types";

export interface EnrollStore {
    status: 'idle' | 'loading' | 'failed';
    request?: {
        payload: SignupPayload | ConfirmPayload | EnrollPayload | EnrollGetPayload,
        status?: string
    }
    error?: GenericError
    enroll?: EnrollGetResponse
}

const mapState = (state: RootState) => ({
    ...state.enroll,
})

const mapDispatch = {
    toggleOn: () => ({ type: 'TOGGLE_IS_ON' }),
}

export const connectorEnroll = connect(mapState, mapDispatch)

export type PropsEnroll = ConnectedProps<typeof connectorEnroll>

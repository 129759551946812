import { ReactNode, useEffect } from "react";
import { Container } from "react-bootstrap";
import VoicemeHeader from "./voiceme-header";
import VoicemeRouter from "../router/voiceme-router";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../app/hooks";


function ContentPage({ children } : {children: ReactNode}) {
    const dispatch = useDispatch()
    return (
    <Container fluid className="p-0">
        {children}
    </Container>
    )
}

export function VoicemePage({ children } : {children: ReactNode}) {
    const dispatch = useAppDispatch()
    return (
        <>
            <VoicemeHeader></VoicemeHeader>
            <ContentPage>
                <VoicemeRouter>
                    {children}
                </VoicemeRouter>
            </ContentPage>
        </>
    )
}

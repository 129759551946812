import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connector, PropsRouter } from '../../app/router/store';
import { Children } from '../../type.d';


interface Prop extends PropsRouter, Children {

}

function VoicemeRouter({ path, routeCleanup, children }: Prop) {
  const navigate = useNavigate();
  useEffect(() => {
      if(path !== '') {
          routeCleanup()
          navigate(path)
      }
      return () => {}
  }, [path, navigate, routeCleanup])
  return (
    <>
     {children}
    </>
  );
}

export default connector(VoicemeRouter);

import { MouseEvent, useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { connectorLogin, PropsLogin } from '../../app/login/store';
import { routerTo } from '../../app/router/actions';
import VoicemeButton from '../atoms/VoicemeButton';
import Success from './Success';
import email from '../../assets/images/email.svg'

interface Prop {
    onClick: ({ otpEmail, otpMobile }: { otpEmail: string, otpMobile: string }) => void
}

function OTPs({ onClick }: Prop) {
    const [validatedEmail, setValidatedEmail] = useState(false);
    const [validatedMobile, setValidatedMobile] = useState(false);
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch()

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        const emailField = form.elements.emailOtp;
        const mobileField = form.elements.mobileOtp;
        onClick({ otpEmail: emailField.value, otpMobile: mobileField.value })
        event.preventDefault();
    }

    const handleValidation = (event: any) => {
        const form = event.currentTarget;
        const emailField = form.elements.emailOtp;
        const mobileField = form.elements.mobileOtp;

        if (emailField.length > 0) {
            emailField.setCustomValidity("Please enter a valid OTP address.");
            setValidatedEmail(false);
        } else {
            setValidatedEmail(true);
            emailField.setCustomValidity("");
        }

        if (mobileField.length > 0) {
            mobileField.setCustomValidity("Please enter a valid OTP address.");
            setValidatedMobile(false);
        } else {
            setValidatedMobile(true);
            mobileField.setCustomValidity("");
        }
        setValidated(true)
    };
    return (
        <Form noValidate validated={validated} onChange={handleValidation} onSubmit={handleSubmit}>
            <Success image={email} title="App" message='Please enter the OTP received by mobile and email' subMessage='We will send you the OTP on phone and email' button='Invio' onClick={() => { }} type="submit">
                <Form.Group validated={validatedEmail} className="w-100" as={Col} md="4" controlId="emailOtp">
                    <Form.Control
                        required
                        type="text"
                        placeholder="OTP Email"
                        defaultValue=""
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group validated={validatedMobile} className="w-100" as={Col} md="4" controlId="mobileOtp">
                    <Form.Control
                        required
                        type="text"
                        placeholder="OTP Mobile"
                        defaultValue=""
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
            </Success>
        </Form>
    );
}

export default connectorLogin(OTPs);

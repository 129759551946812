import { Action } from "redux";
import { Observable } from "rxjs";
import { delay, filter, map, withLatestFrom } from "rxjs/operators";
import { callApiStart } from "../api/actions";
import { RootState } from "../store";
import { notificationFailed, notificationGetFailed, notificationGetProcessed, notificationGetTrigger, notificationProcessed, notificationTrigger } from "./actions";
import { routerTo } from "../router/actions";

export const epicNotificationTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(notificationTrigger.match),
        withLatestFrom(state$),
        filter(([, state]) => state.notification.status === 'loading'),
        map(([action, state]) => {
            return callApiStart({
                url: `${state.api.api!.endpoint}${state.api.api!.notifications}`,
                method: "POST",
                body: JSON.stringify({...action.payload}),
                success: notificationProcessed,
                reject: notificationFailed,
                auth: false,
            })
        })
    );

export const epicNotificationProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(action => notificationProcessed.match(action) || notificationGetProcessed.match(action)),
        withLatestFrom(state$),
        filter(([, state]) => state.notification.notificationStatus === 'NOTIFIED'),
        delay(1000),
        map(([action, state]) => notificationGetTrigger({session_id: (action as any).payload.session_id}))
    );

export const epicNotificationGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(notificationGetTrigger.match),
        withLatestFrom(state$),
        filter(([, state]) => state.notification.notificationStatus === 'NOTIFIED'),
        map(([action, state]) =>
            callApiStart({
                url: `${state.api.api?.endpoint}${state.api.api?.notifications}?session_id=${action.payload.session_id}`,
                method: "GET",
                success: notificationGetProcessed,
                reject: notificationGetFailed,
                auth: false,
            }))
    );

export const epicNotificationSuccess = (action$: Observable<Action>, state$: Observable<RootState>) =>
action$.pipe(
    filter(notificationGetProcessed.match),
    withLatestFrom(state$),
    filter(([action, state]) => action.payload.status === 'SUCCESS'),
    map(([action,]) => routerTo({path: "/success"}))
);

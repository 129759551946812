import { createAction } from "@reduxjs/toolkit";
import { GenericError, VoicemeToken } from "../api/types";
import { ConfirmPayload, EnrollGetPayload, EnrollGetResponse, EnrollPayload, SignupPayload, SignupResponse } from "./types";

export const signupTrigger = createAction<SignupPayload>("signup/post/trigger");
export const signupProcessed = createAction("signup/post/processed");
export const signupFailed = createAction<GenericError>("signup/post/failed");

export const confirmTrigger = createAction<ConfirmPayload>("confirm/post/trigger");
export const confirmProcessed = createAction<VoicemeToken>("confirm/post/processed");
export const confirmFailed = createAction<GenericError>("confirm/post/failed");

export const enrollTrigger = createAction<EnrollPayload>("enroll/post/trigger");
export const enrollProcessed = createAction("enroll/post/processed");
export const enrollFailed = createAction<GenericError>("enroll/post/failed");

export const enrollGetTrigger = createAction<EnrollGetPayload>("enroll/get/trigger");
export const enrollGetProcessed = createAction<EnrollGetResponse>("enroll/get/processed");
export const enrollGetFailed = createAction<GenericError>("enroll/get/failed");

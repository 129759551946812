import { createSlice } from '@reduxjs/toolkit';
import { notificationFailed, notificationGetFailed, notificationGetProcessed, notificationGetTrigger, notificationProcessed, notificationTrigger } from './actions';
import { NotificationStore } from './store';

const initialState: NotificationStore = {
  status: 'idle',
  notificationStatus: '',
  sessionId: ''
}

export const notificationStore = createSlice({
  name: 'notification',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(notificationTrigger, (state, action) => {
        state.status = 'loading'
        state.sessionId = ''
        state.notificationStatus = ''
        state.error = undefined
        state.emailSetup = action.payload.email
      })
      .addCase(notificationProcessed, (state, action) => {
        state.status = 'idle'
        state.sessionId = action.payload.session_id
        state.notificationStatus = action.payload.status
        state.error = undefined
      })
      .addCase(notificationFailed, (state, action) => {
        state.status = 'failed'
        state.sessionId = ''
        state.notificationStatus = ''
        state.error = action.payload
      })
      .addCase(notificationGetTrigger, (state) => {
        state.status = 'loading'
        state.error = undefined
      })
      .addCase(notificationGetProcessed, (state, action) => {
        state.status = 'idle'
        state.sessionId = action.payload.session_id
        state.notificationStatus = action.payload.status
        state.error = undefined
      })
      .addCase(notificationGetFailed, (state, action) => {
        state.status = 'failed'
        state.sessionId = ''
        state.notificationStatus = ''
        state.error = action.payload
      })
  },
});

export default notificationStore.reducer;

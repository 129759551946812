import { useEffect, useState } from 'react';
import { Row, Col, Stack, Image, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router';
import { connectorLogin, PropsLogin } from '../../app/login/store';
import voiceme from '../../assets/images/voiceme.svg';
import top from '../../assets/images/landing/top-background.svg'
import top2 from '../../assets/images/landing/top-background-2.svg'
import bottom from '../../assets/images/landing/bottom-background.svg'
import voicemeSquare from '../../assets/images/landing/voiceme-square.svg'
import voice from '../../assets/images/landing/voice.svg'
import shopping from '../../assets/images/landing/shopping.svg'
import webAuth from '../../assets/images/web-auth.svg'
import mobileAuth from '../../assets/images/mobile-auth.svg'
import laptop from '../../assets/images/laptop.svg'
import phoneImage from '../../assets/images/phone.svg'
import block1 from '../../assets/images/landing/block1.svg'
import block2 from '../../assets/images/landing/block2.svg'
import block3 from '../../assets/images/landing/block3.svg'
import { Link } from 'react-router-dom';
import VoicemeCard from '../atoms/VoicemeCard';
import VoicemeButton from '../atoms/VoicemeButton';

interface Props extends PropsLogin {
    choiceSelection?: (val: string) => void
    name?: string
}

function MobileWebSelector({ email, phone, name, choiceSelection }: Props) {
    const [selection, setSelection] = useState('none')
    const selectionHandler = (state: 'web'|'mobile'|'none') => {
        setSelection(state)
    }
    const welcome = name ? (<strong>Welcome {name}</strong>): (<strong>Login</strong>)
    const baseMessage = name ? (<>Now register your biometric profile.</>) : (<>Where do you prefer to Login?</>)
    const phoneMessage = name ? (<>Use app to scan the QrCode <br />and register your voice</>) : (<>I would like to login  <br />through mobile</>)
    const webMessage = name ? (<>Recording your voice <br />with webcam and audio on Voiceme</>) : (<>I would like to login  <br />through desktop</>)
    return (
        <>
            <Row xs={1} md={1} className="d-flex-fluid w-100 g-4" style={{ backgroundColor: 'var(--voiceme-bg)' }}>
                <Col>
                    <Row xs={1} md={3} className="g-4">
                        <Col>
                        </Col>
                        <Col>
                            <h4 className='text-center'>{welcome}<br />{baseMessage}</h4>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row className="d-flex">
                        <Col xs="6" className="d-flex align-items-end justify-content-end text-end text-black">
                            <VoicemeCard selection={(s)=>selectionHandler(s ? 'web' : 'none')} isSelected={selection==='web'} image={name ? webAuth : laptop}>{webMessage}</VoicemeCard>
                        </Col>
                        <Col xs="6"  className="d-flex align-items-start justify-content-start text-start text-black">
                            <VoicemeCard selection={(s)=>selectionHandler(s ? 'mobile' : 'none')} isSelected={selection==='mobile'} image={name ? mobileAuth : phoneImage}>{phoneMessage}</VoicemeCard>
                        </Col>
                    </Row>
                    <Row className="d-flex-fluid align-items-center justify-content-center text-center mt-5">
                        <Col><VoicemeButton disabled={selection === 'none'} onClick={() => choiceSelection?.(selection)}>Continue</VoicemeButton></Col>
                    </Row>
                </Col>
            </Row >
        </>
    );
}

export default connectorLogin(MobileWebSelector);

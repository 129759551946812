import { MouseEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import errorImage from '../../assets/images/error.svg';
import Register from '../organism/Register';
import MobileWebSelector from '../organism/MobileWebSelector';
import QrCode from '../organism/QrCode';
import Success from '../organism/Success';
import { useAppDispatch } from '../../app/hooks';
import { confirmTrigger, enrollTrigger, signupTrigger } from '../../app/enroll/actions';
import OTPs from '../organism/OTPs';
import Loader from '../organism/Loader';
import { connectorEnroll, PropsEnroll } from '../../app/enroll/store';
import VoicemeRecorder from '../organism/recorder/VoicemeRecorder';
import { GenericError } from '../../app/api/types';

interface Props extends PropsEnroll {
}


function RegisterFlow({ error, status, enroll }: Props) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [state, setState] = useState('start')
    const [errorEnroll, setErrorEnroll] = useState<GenericError>()
    const [prevState, setPrevState] = useState('start')
    const [payload, setPayload] = useState({
        service: {
            uuid: "23a04bc1-ffa1-483e-8bdf-e61f3966fa07",
            muid: "VOICEME"
        },
        user: {
            first_name: "",
            last_name: "",
            mobile_phone: "",
            mail: ""
        }
    })
    const registerHandler = (user: { first_name: string, last_name: string, mobile_phone: string, mail: string }) => {
        setPayload({ ...payload, user: user })
        setState('select')
    }
    const qrHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
        setState('success')
    }
    const otpHandler = ({ otpEmail, otpMobile }: { otpEmail: string, otpMobile: string }) => {
        dispatch(confirmTrigger({
            email: payload.user.mail,
            mobile: payload.user.mobile_phone,
            otp_email: otpEmail,
            otp_mobile: otpMobile,
        }))
        setPrevState('otp')
        setState('loaderOtp')
    }
    const successHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
        navigate('/services')
    }
    useEffect(() => {
        if (state === 'loaderOtp' && status === 'idle' && error === undefined) {
            setState('recorder')
        } else if (state === 'loaderRecorder' && status === 'idle' && error === undefined) {
            setState('success')
        } else if (state === 'loaderRecorder' && status === 'idle' && errorEnroll !== undefined) {
            setErrorEnroll(error)
            setPrevState('start')
            setState('failEnroll')
        } else if (error !== undefined) {
            setErrorEnroll(error)
            setPrevState('start')
            setState('failEnroll')
        }
    }, [error, errorEnroll, status])
    const mobileWebSelection: (val: string) => void = (val) => {
        if (val === 'mobile') {
            setPrevState('mobile')
            setState('mobile')
        } else if (val === 'web') {
            dispatch(signupTrigger({
                first_name: payload.user.first_name,
                last_name: payload.user.first_name,
                email: payload.user.mail,
                mobile: payload.user.mobile_phone,
            }))
            setPrevState('start')
            setState('otp')
        }
    }
    const cancelRequest = () => {
        setState('select')
    }
    switch (state) {
        case 'select':
            return (<MobileWebSelector name={payload.user.first_name} choiceSelection={mobileWebSelection}></MobileWebSelector>)
        case 'mobile':
            return (<QrCode onClick={qrHandler} value={JSON.stringify(payload)} ></QrCode>)
        case 'web':
            return (<QrCode onClick={qrHandler} value='' ></QrCode>)
        case 'otp':
            return (<OTPs onClick={otpHandler} ></OTPs>)
        case 'success':
            return (<Success title="Congrats!" message='You have completed the registration' subMessage='To continune you have to log in, and then you can discover VOICEME!' onClick={successHandler} ></Success>)
        case 'loaderOtp':
            return (<Loader onClick={() => { }}></Loader>)
        case 'loaderRecorder':
            return (<Loader onClick={() => { }}></Loader>)
        case 'recorder':
            return (<VoicemeRecorder cancel={cancelRequest} duration={3}  sentence={enroll?.sentence} action={(payload) => {
                dispatch(enrollTrigger({
                    ...payload,
                    session_id: enroll?.session_id,
                    pin_number: "012345678912",
                }))
                setState('loaderRecorder')
            }}></VoicemeRecorder>)
        case 'fail': {
            let errorFix = error
            if (errorFix?.code === undefined || errorFix.detail === undefined) {
                errorFix = {
                    code: 'GenericError',
                    detail: 'Unknown error'
                }
            }
            return (<Success image={errorImage} onClick={() => setState(prevState)} title='Error' message={errorFix.code} subMessage={errorFix.detail}></Success>)
        }
        case 'failEnroll': {
            let errorFix = errorEnroll
            if (errorFix?.code === undefined || errorFix.detail === undefined) {
                errorFix = {
                    code: 'GenericError',
                    detail: 'Unknown error'
                }
            }
            return (<Success image={errorImage} onClick={() => setState(prevState)} title='Error' message={errorFix.code} subMessage={errorFix.detail}></Success>)
        }
        case 'start':
        default:
            return (<Register onClick={registerHandler}></Register>)
    }
}

export default connectorEnroll(RegisterFlow);

import { connect, ConnectedProps } from "react-redux";
import { GenericError } from "../api/types";
import { RootState } from "../store";

export interface NotificationStore {
    status: 'idle' | 'loading' | 'failed';
    sessionId: string,
    notificationStatus: string
    emailSetup?: string
    error?: GenericError
}

const mapState = (state: RootState) => ({
    ...state.notification,
})

const mapDispatch = {
    toggleOn: () => ({ type: 'TOGGLE_IS_ON' }),
}

export const connectorNotification = connect(mapState, mapDispatch)

export type PropsNotification = ConnectedProps<typeof connectorNotification>

import { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { connectorLogin } from '../../app/login/store';
import Success from './Success';
import email from '../../assets/images/email.svg'

interface Prop {
    onClick: ({ email }: { email: string }) => void
}

function Login({ onClick }: Prop) {
    const [validatedEmail, setValidatedEmail] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        const emailField = form.elements.email;
        onClick({ email: emailField.value })
        event.preventDefault();
    }

    const handleValidation = (event: any) => {
        const form = event.currentTarget;
        const emailField = form.elements.email;

        // Email validation
        const emailRegex = /^\S+@\S+\.\S+$/;
        if (!emailRegex.test(emailField.value)) {
            emailField.setCustomValidity("Please enter a valid email address.");
            setValidatedEmail(false);
        } else {
            setValidatedEmail(true);
            emailField.setCustomValidity("");
        }

        setValidated(true)
    };
    return (
        <Form noValidate validated={validated} onChange={handleValidation} onSubmit={handleSubmit}>
            <Success image={email} title="App" message='Please enter your Email' subMessage='We will send you the OTP on app to continue' button='Invio' onClick={()=>{}} type="submit">
                <Form.Group validated={validatedEmail} className="w-100" as={Col} md="4" controlId="email">
                    <Form.Control
                        required
                        type="text"
                        placeholder="Email"
                        defaultValue=""
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
            </Success>
        </Form>
    );
}

export default connectorLogin(Login);

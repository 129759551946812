import { invokeSaveAsDialog, RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';

// var isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);

export interface IRecording {
    video: string,
    videoType: string,
    audio: string,
    audioType: string
};

class Recorder{
    _videoStream?: MediaStream;
    _audioStream?: MediaStream;
    _recordVideo?: RecordRTCPromisesHandler;
    _recordAudio?: RecordRTCPromisesHandler;

    constructor(options?: MediaRecorderOptions){
        this.reinit()
    }

    reinit() {
        this._videoStream = new MediaStream();
        this._audioStream = new MediaStream();
        this._recordVideo = new RecordRTCPromisesHandler(this._videoStream);
        this._recordAudio = new RecordRTCPromisesHandler(this._videoStream);
    }

    getVideoStream = async () => {
        const videoStream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true,
        });
        const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true
        });

        this._videoStream = videoStream;
        this._audioStream = audioStream;
        
        return videoStream;
    }

    async startVideoRecording() {
        // console.log("Start recording");

        var videoOnlyStream = new MediaStream();
        var audioOnlyStream = new MediaStream();

        this._videoStream!.getVideoTracks().forEach((track) => {
            videoOnlyStream.addTrack(track);
        });

        this._audioStream!.getAudioTracks().forEach((track) => {
            audioOnlyStream.addTrack(track)
        });

        this._recordVideo = new RecordRTCPromisesHandler(videoOnlyStream, {
            type: 'video',
            mimeType: 'video/webm;codecs=vp8'
        });

        this._recordAudio = new RecordRTCPromisesHandler(audioOnlyStream, {
            type: 'audio',
            recorderType: StereoAudioRecorder,
            bufferSize: 4096,
            desiredSampRate: 16000,
            // numberOfAudioChannels: isEdge ? 1 : 2,
        });

        this._recordVideo.startRecording();
        this._recordAudio.startRecording();
    }

    async stopVideoRecording() {
        // console.log("Stop recording");
        await this._recordVideo!.stopRecording();
        await this._recordAudio!.stopRecording();
    }

    async requestRecording() {
        this.stopVideoRecording();
        const videoUrl = await this._recordVideo!.getDataURL();
        const videoType = (await this._recordVideo!.getBlob()).type;
        const audioUrl = await this._recordAudio!.getDataURL();
        const audioType = (await this._recordAudio!.getBlob()).type;
        const recording: IRecording = {
            video: videoUrl, 
            videoType: videoType, 
            audio: audioUrl, 
            audioType: audioType
        }
        const tracks = [...this._videoStream!.getTracks(),...this._audioStream!.getTracks()];
        tracks.forEach((track) => {
          track.stop();
        });
        return recording;
    }

    saveToDisk(blob: Blob, fileName?: string) {
        const recorderName = fileName || "recorder.webm";
        invokeSaveAsDialog(blob, recorderName);
    }
}

export const RecorderInstance = new Recorder();
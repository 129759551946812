import { useEffect, useRef, VideoHTMLAttributes } from "react";

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
    srcObject: MediaStream 
}
const VideoPlayer = ({ srcObject, ...props }: PropsType) => {
    const refVideo = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!refVideo.current) return
        refVideo.current.srcObject = srcObject
    }, [srcObject])
    
    return <div style={{overflow: "hidden"}}><video style={{scale: "1.5"}} ref={refVideo} {...props} autoPlay /></div>
}

export default VideoPlayer;

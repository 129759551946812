import { useEffect, useRef, useState } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import base64 from 'base-64';
import { useAppDispatch } from "../../app/hooks";

const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);
    if (!stream) {
        return null;
    }
    return <video ref={videoRef} width={500} height={500} autoPlay />;
};

const Recorder = () => {
    const dispatch = useAppDispatch()
    const [videoAction, setVideoAction] = useState<{ startRecording: () => void, stopRecording: () => void }>()
    const [audioAction, setAudioAction] = useState<{ startRecording: () => void, stopRecording: () => void }>()
    const [startup, setStartup] = useState(false)
    const [videoBase64, setVideoBase64] = useState<string | null>(null);
    const [audioBase64, setAudioBase64] = useState<string | null>(null);

    const startMedia = () => {
        if (videoAction && audioAction) {
            // dispatch(initAudioVideo())
            // videoAction.stopRecording()
            videoAction.startRecording()
            audioAction.startRecording()
            setTimeout(stopMedia, 5000)
        }
    }
    useEffect(() => {
        if (!startup && videoAction) {
            // videoAction.startRecording()
            setStartup(true)
        }
    }, [videoAction])

    const stopMedia = () => {
        if (videoAction && audioAction) {
            videoAction.stopRecording()
            audioAction.stopRecording()
        }
    }

    const handleVideoBlob = (blobUrl: string | undefined) => {
        if (!blobUrl) return
        fetch(blobUrl)
            .then(res => res.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    setVideoBase64(base64data as string);
                    // dispatch(appendVideo(video: base64data))
                };
            });
    }

    const handleAudioBlob = (blobUrl: string | undefined) => {
        if (!blobUrl) return
        fetch(blobUrl)
            .then(res => res.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    setAudioBase64(base64data as string);
                    // dispatch(appendAudio({audio: base64data}))
                };
            });
    }

    return (
        <div>
            <ReactMediaRecorder
                video={true} audio={false}
                render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream }) => {
                    videoAction || setVideoAction({ startRecording, stopRecording })
                    handleVideoBlob(mediaBlobUrl);
                    return (<VideoPreview stream={previewStream} />)
                }}
            />
            <ReactMediaRecorder
                video={false} audio={true}
                render={({ status, startRecording, stopRecording, mediaBlobUrl }) => {
                    audioAction || setAudioAction({ startRecording, stopRecording })
                    handleAudioBlob(mediaBlobUrl);
                    return (
                        <div>
                            <p>{status}</p>
                        </div>
                    )
                }}
            />
            <button onClick={() => startMedia()}>start media</button>
        </div>
    )
};

export default Recorder;

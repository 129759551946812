import { Anchor, Card, Col, Image, Row } from 'react-bootstrap';
import { connectorLogin, PropsLogin } from '../../app/login/store';

import decathlon from '../../assets/images/decathlon.png';
import lafeltrinelli from '../../assets/images/lafeltrinelli.png';
import monclick from '../../assets/images/monclick.png';
import pinko from '../../assets/images/pinko.png';
import samsung from '../../assets/images/samsung.png';
import ticketone from '../../assets/images/ticketone.png';
import trenitalia from '../../assets/images/trenitalia.png';
import unieuro from '../../assets/images/unieuro.png';
import top from '../../assets/images/background-top.svg';
import vme2 from '../../assets/images/vme2.svg';
import VoicemeBanner from '../atoms/VoicemeBanner';
import bottom from '../../assets/images/landing/bottom-background.svg'
import mic from '../../assets/images/mic.svg'
import glassMic from '../../assets/images/glass-mic.svg'

interface ServiceData {
  title: string
  description: string
  image: string
  link: string
  type: string
}

function MyCard({ enabled, service }: { enabled: boolean, service: ServiceData }) {
  if (enabled) {
    return (
      <a href={service.link}>
        <Card.Img variant="top" src={service.image} />
      </a>
    )
  } else {
    return (
      <Card.Img variant="top" src={service.image} />
    )
  }
}

function Service({ enabled, service }: { enabled: boolean, service: ServiceData }) {
  return (
    <Card>
      {MyCard({ enabled, service })}
      <Card.Body>
        <Card.Title>{service.title}</Card.Title>
        <Card.Text>{service.description}</Card.Text>
      </Card.Body>
    </Card>
  )
}

const services = [
  {
    title: "Unieuro",
    description: "",
    image: unieuro,
    link: "/mock/unieuro.html",
    type: 'service',
  },
  {
    title: "Samsung",
    description: "",
    image: samsung,
    link: "/samsung",
    type: 'service',
  },
  {
    title: "Decathlon",
    description: "",
    image: decathlon,
    link: "/decathlon",
    type: 'service',
  },
  {
    title: "Ticketone",
    description: "",
    image: ticketone,
    link: "/ticketone",
    type: 'service',
  },
  {
    title: "Trenitalia",
    description: "",
    image: trenitalia,
    link: "/mock/trenitalia-home.html",
    type: 'recent',
  },
  {
    title: "Monclick",
    description: "",
    image: monclick,
    link: "/monclick",
    type: 'recent',
  },
  {
    title: "LaFeltrinelli",
    description: "",
    image: lafeltrinelli,
    link: "/lafeltrinelli",
    type: 'recent',
  },
  {
    title: "Pinko",
    description: "",
    image: pinko,
    link: "/pinko",
    type: 'recent',
  },
] as ServiceData[]

interface Props extends PropsLogin {

}

function Services({ email, phone }: Props) {
  return (
    <Row className="w-100 m-0">
      <Col xs={12} className=" m-0 p-0">
        <div style={{ position: 'absolute', zIndex: -1, top: 0, width: '100%', height: '1500px', backgroundImage: `url("${top}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
        <Row xs={1} className="d-flex mt-5 mb-5 pt-5  ps-0 ms-0 me-0 ps-0 w-100 text-center">
          <Col xs={12}><Image src={vme2} /></Col>
        </Row>
        <Row xs={1} className="d-flex mb-5 pb-5 ps-0 ms-0 me-0 ps-0 w-100 text-center text-light fs-1">
          <Col xs={12}>Shop with your Voice, <br />the One and Only</Col>
        </Row>
        <Row className="d-flex-fluid w-100 text-center text-light">
          <Col xs={11}></Col>
          <Col xs={1}>{email && <Anchor href="https://voiceme.staging.iio.infra.foolfarm.com/trenitalia"><Image src={glassMic}></Image></Anchor>}</Col>
        </Row>
        <Row xs={1} className="d-flex mb-5 pb-5 ps-0 ms-0 me-0 ps-0 w-100 text-center">
          <Col xs={1}></Col>
          <Col xs={5}><VoicemeBanner button="Shop" title="Buy with VOICEME" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco" ></VoicemeBanner></Col>
          <Col xs={5}><VoicemeBanner buttonIcon={mic} button="VoiceBot" title="Search with VOICEME" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco" ></VoicemeBanner></Col>
          <Col xs={1}></Col>
        </Row>
        <Row xs={1} className="d-flex mb-3 text-center">
          <Col xs={2}></Col>
          <Col xs={10} className="fs-3 text-start text-dark">Selected for you</Col>
        </Row>
        <Row>
          <Col xs={2}></Col>
          {services.filter(p => p.type == 'service').map((service: ServiceData) => (
            <Col xs={2}><Service enabled={email !== '' || phone !== ''} key={service.title} service={service}></Service></Col>
          ))}
          <Col xs={2}></Col>
        </Row>
        <Row xs={1} className="d-flex mb-3 mt-5 text-center">
          <Col xs={2}></Col>
          <Col xs={10} className="fs-3 text-start text-dark">Recent</Col>
        </Row>
        <Row className="">
          <Col xs={2}></Col>
          {services.filter(p => p.type == 'recent').map((service: ServiceData) => (
            <Col xs={2}><Service enabled={email !== '' || phone !== ''} key={service.title} service={service}></Service></Col>
          ))}
          <Col xs={2}></Col>
        </Row>
        <Row  >
          <Col xs={12} style={{ height: '300px', backgroundImage: `url("${bottom}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default connectorLogin(Services);

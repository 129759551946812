import { MouseEventHandler } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Children } from '../../type.d';

interface Props extends Children {
    image: string
    selection: (isSelected: boolean) => void
    isSelected: boolean
}

function VoicemeCard({ image, children, isSelected, selection }: Props) {
    const handler: MouseEventHandler<HTMLElement> = (event) => {
        selection(!isSelected)
    }

    return (
        <Row className="d-flex align-items-center justify-content-center text-center mt-5 mb-5 me-2 ms-2 p-2">
            <Col onClick={handler} style={{ minWidth: '200px' , maxWidth: '300px' , borderColor: 'black', background: "white", textAlign: 'center', borderRadius: '20px', borderStyle: (isSelected ? 'solid':'hidden') }}>
                <Row>
                    <Col xs={12} className="mt-4 mb-4" >
                        <Image className="mt-4" src={image} width={40} height={40} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mb-5 text-sm-center" style={{ fontSize: '0.6em' }} >
                        {children}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default VoicemeCard;

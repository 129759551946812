import { createAction } from "@reduxjs/toolkit";
import { GenericError, VoicemeToken } from "../api/types";
import { LoginGetPayload, LoginGetResponse, LoginSetupPayload, LoginPostPayload, FlowSetupPayload } from "./types";

export const setupLogin = createAction<LoginSetupPayload>("login/setup");

export const loginTrigger = createAction<LoginPostPayload>("login/post/trigger");
export const loginProcessed = createAction<VoicemeToken>("login/post/processed");
export const loginFailed = createAction<GenericError>("login/post/failed");

export const loginGetTrigger = createAction<LoginGetPayload>("login/get/trigger");
export const loginGetProcessed = createAction<LoginGetResponse>("login/get/processed");
export const loginGetFailed = createAction<GenericError>("login/get/failed");

export const loginFlowSetup = createAction<FlowSetupPayload>("login-flow/setup");

import { useState } from 'react';
import { Button, Col, Row, Form, Anchor } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setupLogin } from '../../app/login/actions';
import { connectorLogin, PropsLogin } from '../../app/login/store';
import { routerTo } from '../../app/router/actions';
import VoicemeButton from '../atoms/VoicemeButton';

interface Prop {
    onClick: ({first_name, last_name, mail, mobile_phone}: {first_name: string, last_name: string, mobile_phone: string, mail: string}) => void
}

function Register({onClick}: Prop) {
    const [validatedEmail, setValidatedEmail] = useState(false);
    const [validatedPhone, setValidatedPhone] = useState(false);
    const [validatedName, setValidatedName] = useState(false);
    const [validatedSurname, setValidatedSurname] = useState(false);
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        const emailField = form.elements.email;
        const phoneField = form.elements.phone;
        const nameField = form.elements.name;
        const surnameField = form.elements.surname;

        dispatch(setupLogin({email: emailField.value, phone: phoneField.value}))
        onClick({first_name: nameField.value, last_name: surnameField.value, mobile_phone: phoneField.value, mail: emailField.value})
        event.preventDefault();
        event.stopPropagation();
    }

    const handleValidation = (event: any) => {
        const form = event.currentTarget;
        const emailField = form.elements.email;
        const phoneField = form.elements.phone;
        const nameField = form.elements.name;
        const surnameField = form.elements.surname;

        const emailRegex = /^\S+@\S+\.\S+$/;
        if (!emailRegex.test(emailField.value)) {
            emailField.setCustomValidity("Please enter a valid email address.");
            setValidatedEmail(false);
        } else {
            setValidatedEmail(true);
            emailField.setCustomValidity("");
        }

        const phoneRegex = /^\+\d{2}\d{10}$/;
        if (!phoneRegex.test(phoneField.value)) {
            phoneField.setCustomValidity("Please enter a valid 10-digit phone number.");
            setValidatedPhone(false);
        } else {
            setValidatedPhone(true);
            phoneField.setCustomValidity("");
        }

        if (nameField.length > 0) {
            nameField.setCustomValidity("Please enter a valid name.");
            setValidatedName(false);
        } else {
            setValidatedName(true);
            nameField.setCustomValidity("");
        }

        if (surnameField.length > 0) {
            surnameField.setCustomValidity("Please enter a valid surname.");
            setValidatedSurname(false);
        } else {
            setValidatedSurname(true);
            surnameField.setCustomValidity("");
        }

        setValidated(true)
    };
    return (
        <Row className="w-100">
            <Col xs={12}>
            <Row className='text-center p-5'>
                <Col xs={12} className="text-primary fs-1">Create your VOICEME account</Col>
            </Row>
            <Form noValidate validated={validated} onChange={handleValidation} onSubmit={handleSubmit}>
            <Row xs={1} md={3} className="g-4">
                <Col>
                </Col>
                <Col>
                    <Row >
                        <Col className="mt-4">
                            <Form.Group validated={validatedName} className="w-100" as={Col} md="4" controlId="name">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Name"
                                    defaultValue=""
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="mt-4">
                            <Form.Group validated={validatedSurname} className="w-100" as={Col} md="4" controlId="surname">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Surname"
                                    defaultValue=""
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row >
                        <Col className="mt-4">
                            <Form.Group validated={validatedEmail} className="w-100" as={Col} md="4" controlId="email">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Email"
                                    defaultValue=""
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="mt-4">
                            <Form.Group validated={validatedPhone} className="w-100" as={Col} md="4" controlId="phone">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Phone"
                                    defaultValue=""
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='d-flex-fluid p-5'>
                        <Col xs={12} className="text-center">
                            <VoicemeButton onClick={()=>{}} disabled={!(validatedEmail && validatedPhone && validatedSurname && validatedName)} type="submit">Create</VoicemeButton>
                        </Col>
                    </Row>
                    <Row className='p-5'>
                        <Col xs={12}>Have an account? <Anchor onClick={() => navigate('/login')}>Login</Anchor></Col>
                    </Row>
                </Col>
                <Col>
                </Col>
            </Row>
        </Form>

            </Col>
        </Row>
    );
}

export default connectorLogin(Register);

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store";
import { routerTo } from "./actions";

export interface RouterStore {
    path: string,
}

const mapState = (state: RootState) => ({
    ...state.router,
})

const mapDispatch = {
    routeLogin: () => (routerTo({ path: '/login' })),
    routeCleanup: () => (routerTo({ path: '' })),
}

export const connector = connect(mapState, mapDispatch)

export type PropsRouter = ConnectedProps<typeof connector>
